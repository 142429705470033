<template>
    <div id="index">
        <div class="container">
            <div class="top_text" @click="goBack">
                <p>你的位置: 首页 / 购物车</p>
            </div>
            <div class="content_individual">
                <div class="content_details" v-if="list.length > 0">
                    <div class="flex flex-child-center flex-justify-content-between" style="padding: 0.2rem 0; border-bottom: 0.01rem solid #E4E4E4;">
                        <div class="flex flex-child-center" style="width: 8%;padding-left: 0.5rem;"><el-checkbox v-model="isAllCheck" @change="selectedAll">全选</el-checkbox></div>
                        <div class="flex flex-child-center" style="width: 47%;padding: 0 1rem;">商品名称</div>
                        <div class="flex flex-child-center flex-justify-content-center" style="width: 10%;">单价</div>
                        <div class="flex flex-child-center flex-justify-content-center" style="width: 15%;">数量</div>
                        <div class="flex flex-child-center flex-justify-content-center" style="width: 10%;">小计</div>
                        <div class="flex flex-child-center flex-justify-content-center" style="width: 10%;">操作</div>
                    </div>
                    <div class="flex flex-child-center flex-justify-content-between" style="padding: 0.1rem 0; border-bottom: 0.01rem solid #E4E4E4;" v-for="(item, index) in list" :key="index">
                        <div class="flex flex-child-center" style="width: 8%;padding-left: 0.5rem;"><el-checkbox v-model="item.checked" @change="itemCheck(index)"></el-checkbox></div>
                        <div class="flex flex-child-center flex-justify-content-center" style="width: 47%;padding: 0 0.2rem">
                            <div class="flex" style="width: 100%;">
                                <div class="gwcimg"><img :src="item.goods.goods_image"></div>
                                <div class="gwctext">
                                    <div>{{ item.goods.goods_name }}</div>
                                    <div style="font-size: 0.16rem;font-weight: 400;color: #999999;line-height: 0.2rem;margin-top: 0.09rem;">{{ item.goods.goods_spec }}</div>
                                </div>
                            </div>

                        </div>
                        <div class="flex flex-child-center flex-justify-content-center" v-if="$store.state.turnOnOff" style="width: 10%;font-weight: normal;font-size: 0.2rem;color: #333333;">
                            {{ item.goods.price_profit }}</div>
                        <div class="flex flex-child-center flex-justify-content-center" v-else style="width: 10%;font-weight: normal;font-size: 0.2rem;color: #333333;">
                            {{ item.goods.old_price }}</div>
                        <div class="flex flex-child-center flex-justify-content-center" style="width: 15%;">
                            <div class="num_input flex">
                                <el-button class="btn_minute" @click="btnMinute(item)">-</el-button>
                                <div class="input">
                                    <el-input v-model="item.number" @input="number($event, item)" type="number" class="numrule"></el-input>
                                </div>

                                <el-button class="btn_add" @click="btnAdd(item)">+</el-button>
                            </div>
                        </div>
                        <div class="flex flex-child-center flex-justify-content-center" style="width: 10%;font-size: 0.2rem;font-weight: normal;color: #4B83F0;">
                            ￥{{ $store.state.turnOnOff ? item.goods.price_profit * item.number : item.goods.old_price * item.number }}
                        </div>
                        <div class="flex flex-child-center flex-justify-content-center" @click="del(item)" style="width: 10%;cursor: pointer;"><img src="../assets/images/del.png"></div>
                    </div>

                </div>
                <div v-else class="content_details" style="height: 7.2rem;padding-top: 1rem;">
                    <el-empty :image="require('../assets/images/null.png')" description="暂无商品"></el-empty>
                </div>
                <div class="content_bottom flex flex-child-center flex-justify-content-between">
                    <div>共<span>&nbsp;&nbsp;{{ list.length }}&nbsp;&nbsp;</span>件商品&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;已选择
                        <span>&nbsp;&nbsp;{{ chooseList.length }}&nbsp;&nbsp;</span>件
                    </div>
                    <div class="flex flex-child-center" style="height: 100%;">
                        <div style="font-size: 0.24rem;font-weight: 400;color: #CF7AE2;margin-right: 0.3rem;" class="flex flex-child-center">
                            总计 <span style="display:block;font-size: 0.32rem;font-weight: normal;">¥{{ totalPrice }}</span>
                        </div>
                        <div class="button" @click="buttonSubmit" style="cursor: pointer;">去结算</div>
                    </div>
                </div>
                <div class="bottom_text">{{ $store.state.text }}</div>
            </div>

        </div>
    </div>
</template>

<script>
export default {
    name: "shop",
    data() {
        return {
            isAllCheck: false,
            list: [],
            chooseList: [],
            totalPrice: 0,

        };
    },
    computed: {
    },
    watch: {
    },
    async mounted() {
        // this.$store.commit('settab', 2)
        this.onList()
    },
    created() {
        this.$store.watch((state, getters) => {
            return state.turnOnOff
        }, (res) => {
            let b = 0
            this.list.forEach(item => {
                if (item.checked) {
                    if (this.$store.state.turnOnOff) {
                        b = (item.number * item.goods.price_profit) + b
                    } else {
                        b = (item.number * item.goods.old_price) + b
                    }
                    // b = (item.number*item.goods.price)+b
                }
            })
            this.totalPrice = b
        })
    },
    methods: {
        goBack(item) {
            this.$router.push({
                name: 'Index'
            })
        },
        /**
         * 去结算点击
         * */
        buttonSubmit() {
            if (this.chooseList.length <= 0) {
                this.$message({ message: '请先选择商品！' });
                return;
            }
            this.$store.commit('setshop', this.chooseList);
            this.$router.push('/ConfirmOrder');
        },
        /**
         *  删除购物车
         * */
        del(item) {
            this.$axios.post('api/kuerp/order/index/delCart', this.$qs.stringify({
                cart_id: item.id,
            })).then(res => {
                if (res.data.code == 200) {
                    // this.barList = res.data.data
                    this.onList()
                    //购物车数量接口
                    this.$store.dispatch('GetGwc')
                }
            })
        },
        /**
         * 输入框输入
         * */
        number(eve, item) {
            if (eve == '') {
                eve = 1
            }
            this.$axios.post('api/kuerp/order/index/editCart', this.$qs.stringify({
                goods_id: item.goods_id,
                unit_id: item.unit_id,
                number: eve
            })).then(res => {
                if (res.data.code == 200) {
                    // this.barList = res.data.data
                    this.onList()
                    //购物车数量接口
                    this.$store.dispatch('GetGwc')
                }
            })
        },
        /**
         *  添加购物车
         * */
        btnAdd(item) {
            this.$axios.post('api/kuerp/order/index/addCart', this.$qs.stringify({
                goods_id: item.goods_id,
                unit_id: item.goods_unit,
                number: 1
            })).then(res => {
                if (res.data.code == 200) {
                    // this.barList = res.data.data
                    this.onList()
                    //购物车数量接口
                    this.$store.dispatch('GetGwc')
                }
            })
        },
        /**
         *  减少购物车
         * */
        btnMinute(item) {
            if (item.number <= 0) {
                this.$message({ message: '该商品不能减少了！' });
                return;
            }
            this.$axios.post('api/kuerp/order/index/editCart', this.$qs.stringify({
                goods_id: item.goods_id,
                unit_id: item.unit_id,
                number: item.number - 1
            })).then(res => {
                if (res.data.code == 200) {
                    // this.barList = res.data.data
                    this.onList()
                    //购物车数量接口
                    this.$store.dispatch('GetGwc')
                }
            })
        },
        onList() {
            this.$axios.post('api/kuerp/order/index/getCart', {

            }).then(res => {
                this.list = res.data.data;
                this.list.forEach((item1, index1) => {
                    this.chooseList.forEach((item, index) => {
                        if (item1.id == item.id) {
                            item1.checked = item.checked
                        }
                    })

                })
                let getId = this.list.map(item => item.id)
                let newArr = this.chooseList.filter(item => getId.includes(item.id))
                this.chooseList = newArr
                let b = 0
                this.list.forEach(item => {
                    if (item.checked) {
                        if (this.$store.state.turnOnOff) {
                            b = (item.number * item.goods.price_profit) + b
                        } else {
                            b = (item.number * item.goods.old_price) + b
                        }
                        // b = (item.number*item.goods.price)+b
                    }
                })
                this.totalPrice = b

            })
        },
        /***
         * 控制全选 全不选
         */
        selectedAll() {
            this.list.forEach(item => {
                item.checked = this.isAllCheck
            })
            let a = []
            let b = 0
            this.list.forEach(item => {
                if (item.checked) {
                    a.push(item)
                    if (this.$store.state.turnOnOff) {
                        b = (item.number * item.goods.price_profit) + b
                    } else {
                        b = (item.number * item.goods.old_price) + b
                    }
                }
            })
            this.chooseList = a
            this.totalPrice = b
        },
        /***
         * 多选框
         */
        itemCheck(index) {
            const preCheckedAll = this.isAllCheck
            if (!this.list[index].checked) {
                this.isAllCheck = false
            } else {
                this.isAllCheck = !this.list.some(item => !item.checked)
            }
            let a = []
            let b = 0
            this.list.forEach(item => {
                if (item.checked) {
                    a.push(item)
                    if (this.$store.state.turnOnOff) {
                        b = (item.number * item.goods.price_profit) + b
                    } else {
                        b = (item.number * item.goods.old_price) + b
                    }
                }
            })
            this.chooseList = a
            this.totalPrice = b
        },

    },
}
</script>

<style scoped lang="scss">
::v-deep {

    .numrule input::-webkit-outer-spin-button,
    .numrule input::-webkit-inner-spin-button {
        -webkit-appearance: none !important;
    }

    .numrule input[type="number"] {
        -moz-appearance: textfield;
    }
}

.gwcimg {
    width: 0.85rem;
    height: 0.85rem;
    margin-right: 0.2rem;

    img {
        width: 100%;
        height: 100%;
    }
}

.gwctext {
    width: 80%;
    font-size: 0.18rem;
    font-weight: 500;
    color: #333333;
    line-height: 0.3rem;
}

.num_input {
    .input {
        width: 0.8rem;
        text-align: center;
        margin: 0 0.05rem;
    }

    ::v-deep {
        .el-button {
            width: 0.48rem !important;
            height: 0.48rem !important;
            padding: 0 !important;
        }

        .el-input__inner {
            height: 0.48rem;
            line-height: 0.48rem !important;
        }
    }
}

#index {
    //width: 100%;
    //height: 100vh;
    background: #434655;
}

.content_individual {
    width: 100%;
    //height: calc(100% - 100px);
    overflow: hidden;
    overflow-y: auto;
    background: #434655;
    padding-bottom: 0.5rem;

    .content_details {
        width: 100%;
        //height: 80%;
        height: 7.2rem;
        background: #FFFFFF;
        //padding: 45px 0.2rem 30px 0.2rem;
        border-radius: 0.04rem;
        font-size: 0.18rem;
        font-weight: 400;
        overflow: hidden;
        overflow-y: auto;
    }

    .content_bottom {
        width: 100%;
        height: 0.8rem;
        background: #FFFFFF;
        border-radius: 0.04rem;
        margin-top: 0.2rem;
        padding-left: 0.74rem;
        font-size: 0.18rem;
        font-weight: 400;
        color: #666666;

        span {
            font-size: 0.24rem;
            font-weight: 400;
            color: #CF7AE2;
        }

        .button {
            width: 3.34rem;
            height: 100%;
            background: #4B83F0;
            border-radius: 0px 0.02rem 0.02rem 0px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 0.18rem;
            font-weight: 600;
            color: #F9F4E4;
        }
    }
}

.bottom_text {
    font-size: 0.14rem;
    font-weight: 500;
    color: #999999;
    text-align: center;
    margin-top: 0.5rem;
}

.container {
    width: 90%;
    margin: 0 auto;
    height: calc(100vh - 1.66rem);

}

.top_text {
    font-size: 0.14rem;
    font-weight: 400;
    color: #FFFFFF;
    margin-top: 0.5rem;
    margin-bottom: 0.3rem;
    line-height: 0.2rem;
}
</style>
